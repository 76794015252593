import Button from "@/comps/button"
import { WrappedFooter } from "@/comps/footer"
import Meta from "@/comps/meta"
import { propsWithTranslation } from "@/ssr/common"

import { devices } from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"

import Cookies from "@/comps/cookies"
import Favicon from "@/comps/favicon"

import {
  AdjustProvider,
  UsePageViewEvent,
} from "@/utils/adjust"
import { useTranslation } from "next-i18next"
import { Box } from "react-styled-flex"
import styled from "styled-components"

import { GlobalStyle } from "./careers"
import { AppProviderProps, queryClient } from "./_app"

import { ClientSideAuthProvider } from "@/utils/client-auth"
import { GoogleOneTap } from "@/utils/googleapi"
import { NotificationProvider } from "@/utils/notification"

import { CoinsProvider } from "@/utils/coins"
import { ThemeProvider } from "next-themes"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { QueryClientProvider } from "react-query"
import { Header } from "../comps"
import { EditorParamsProviders } from "../sections/editor/editor"

const columns = [
  [assetUrl("/404/0-0.webp"), assetUrl("/404/0-1.webp")],
  [assetUrl("/404/1-0.webp"), assetUrl("/404/1-1.webp")],
  [assetUrl("/404/2-0.webp"), assetUrl("/404/2-1.webp")],
  [assetUrl("/404/3-0.webp")],
  [assetUrl("/404/4-0.webp"), assetUrl("/404/4-1.webp")],
  [assetUrl("/404/5-0.webp"), assetUrl("/404/5-1.webp")],
  [
    assetUrl("/404/6-0.webp"),
    assetUrl("/404/6-1.webp"),
    assetUrl("/404/6-2.webp"),
  ],
]

const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 1500px;

  @media ${devices.tablet} {
    height: 100vh;
    min-height: 1300px;
  }

  @media ${devices.desktop} {
    min-height: 1200px;
  }
`

const Body = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 28px;
`

const ImageColumns = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  gap: 8px;

  width: var(--w-screen);
  min-width: 1000px;

  margin-bottom: -100px;

  @media ${devices.desktop} {
    margin-bottom: -150px;
  }
`

const SingleColumn = styled.div`
  display: flex;
  flex-direction: column;

  gap: 8px;
`

const BottomGradient = styled.div`
  width: 100%;

  height: 200px;

  @media ${devices.desktop} {
    height: 350px;
  }

  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;

  .light & {
    background: linear-gradient(
      180deg,
      rgba(245, 246, 252, 0) 0%,
      #f5f6fc 109.43%
    );
  }

  .dark & {
    background: linear-gradient(
      180deg,
      rgba(18, 18, 18, 0) 0,
      #121212 109.43%
    );
  }
`

const OopsText = styled.h1`
  font-weight: 700;
  font-size: 31px;
  line-height: 37px;

  color: var(--color-blue-900);
`

const SubtitleText = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;

  color: var(--color-blue-900);
`

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Meta
        title={t("txt_404_title")}
        description={t("txt_404_title")}
      />
      <Header />
      <Body>
        <Box height={100} />
        <OopsText>404</OopsText>
        <SubtitleText>{t("txt_not_found")}</SubtitleText>
        <Button
          href="/"
          primary
          text={"txt_take_me_back"}
        />
        <ImageColumns>
          {columns.map((column, i) => (
            <SingleColumn key={i}>
              {column.map((image, j) => (
                <img src={image} key={j} alt="404 image" />
              ))}
            </SingleColumn>
          ))}
        </ImageColumns>
        <BottomGradient />
      </Body>
      <WrappedFooter />
    </Container>
  )
}

export async function getStaticProps() {
  return await propsWithTranslation({})
}

NotFound.Layout = function Layout(props: AppProviderProps) {
  const { children, theme } = props

  return (
    <ThemeProvider attribute="class" forcedTheme={theme}>
      <GlobalStyle />

      <QueryClientProvider client={queryClient}>
        <ClientSideAuthProvider>
          <NotificationProvider timeout={3000}>
            <GoogleReCaptchaProvider
              scriptProps={{ async: true }}
              reCaptchaKey={
                process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!
              }>
              <CoinsProvider>
                <AdjustProvider>
                  <Favicon />
                  <Cookies />
                  <EditorParamsProviders>
                    {children}
                  </EditorParamsProviders>
                  <UsePageViewEvent />
                  <GoogleOneTap />
                </AdjustProvider>
              </CoinsProvider>
            </GoogleReCaptchaProvider>
          </NotificationProvider>
        </ClientSideAuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default NotFound
